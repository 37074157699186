import {Key} from "@avvoka/shared";
import { type Directive } from 'vue'
import { addEvt, cleanEvt, position } from '../utils/event'
import { isKeyCode } from '../utils/key-composition'
import throttle from '../utils/throttle'

export type CTX = {start: (e: Event) => void, keystart: (event: KeyboardEvent) => void, enabled?: boolean, abort: VoidFunction[], modifiers: {keyCodes?: number[], color?: string, center?: boolean, stop?: boolean, early?: boolean}}

function showRipple(event: Event, el: HTMLElement, ctx: CTX, forceCenter?: boolean) {
  ctx.modifiers.stop === true && event.stopPropagation()

  const color = ctx.modifiers.color
  let center = ctx.modifiers.center
  center = center === true || forceCenter === true

  const node = document.createElement('span'),
    innerNode = document.createElement('span'),
    pos = position(event),
    { left, top, width, height } = el.getBoundingClientRect(),
    diameter = Math.sqrt(width * width + height * height),
    radius = diameter / 2,
    centerX = `${(width - diameter) / 2}px`,
    x = center ? centerX : `${pos.left - left - radius}px`,
    centerY = `${(height - diameter) / 2}px`,
    y = center ? centerY : `${pos.top - top - radius}px`

  innerNode.className = 'a-ripple__inner'
  innerNode.style.height = `${diameter}px`
  innerNode.style.width = `${diameter}px`
  innerNode.style.transform = `translate3d(${x},${y},0) scale3d(.2,.2,1)`
  innerNode.style.opacity = '0'

  node.className = `a-ripple${color ? ' text-' + color : ''}`
  node.setAttribute('dir', 'ltr')
  node.appendChild(innerNode)
  el.appendChild(node)

  const abort = () => {
    node.remove()
    clearTimeout(timer)
  }
  ctx.abort.push(abort)

  let timer = setTimeout(() => {
    innerNode.classList.add('a-ripple__inner--enter')
    innerNode.style.transform = `translate3d(${centerX},${centerY},0) scale3d(1,1,1)`
    innerNode.style.opacity = '0.2'

    timer = setTimeout(() => {
      innerNode.classList.remove('a-ripple__inner--enter')
      innerNode.classList.add('a-ripple__inner--leave')
      innerNode.style.opacity = '0'

      timer = setTimeout(() => {
        node.remove()
        ctx.abort.splice(ctx.abort.indexOf(abort), 1)
      }, 275)
    }, 250)
  }, 50)
}

function updateModifiers(ctx: CTX, { modifiers, value, arg }) {
  const cfg = Object.assign({}, modifiers, value)
  ctx.modifiers = {
    early: cfg.early,
    stop: cfg.stop,
    center: cfg.center,
    color: cfg.color || arg,
    keyCodes: [].concat(cfg.keyCodes || Key.Enter)
  }
}

export const vRipple: Directive = {
  beforeMount(el, binding: any) {
    const ctx: CTX = {
      enabled: binding.value !== false,
      modifiers: {},
      abort: [],

      start(event: Event) {
        if (
          ctx.enabled &&
          event.aSkipRipple !== true &&
          (ctx.modifiers.early === true
            ? ['mousedown', 'touchstart'].includes(event.type)
            : event.type === 'click')
        ) {
          showRipple(event, el, ctx, event.aKeyEvent === true)
        }
      },

      keystart: throttle((event: KeyboardEvent) => {
        if (
          ctx.enabled &&
          event.aSkipRipple !== true &&
          isKeyCode(event, ctx.modifiers.keyCodes) &&
          event.type === `key${ctx.modifiers.early === true ? 'down' : 'up'}`
        ) {
          showRipple(event, el, ctx, true)
        }
      }, 300)
    }

    updateModifiers(ctx, binding)

    el.__aripple = ctx

    addEvt(ctx, 'main', [
      [el, 'mousedown', 'start', 'passive'],
      [el, 'touchstart', 'start', 'passive'],
      [el, 'click', 'start', 'passive'],
      [el, 'keydown', 'keystart', 'passive'],
      [el, 'keyup', 'keystart', 'passive']
    ])
  },

  updated(el, binding: any) {
    if (binding.oldValue !== binding.value) {
      const ctx = el.__aripple
      ctx.enabled = binding.value !== false

      if (ctx.enabled === true && Object(binding.value) === binding.value) {
        updateModifiers(ctx, binding)
      }
    }
  },

  beforeUnmount(el) {
    const ctx = el.__aripple
    ctx.abort.forEach((fn: VoidFunction) => {
      fn()
    })
    cleanEvt(ctx, 'main')
  }
}
