export default function throttle<T>(
  fn: (...args: any[]) => T,
  limit = 250
) {
  let wait = false,
    result: T

  return function (/* ...args */) {
    if (!wait) {
      wait = true
      setTimeout(() => {
        wait = false
      }, limit)
      result = fn.apply(this, arguments)
    }

    return result
  }
}
